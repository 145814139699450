import banner from '../img/banner2.png'

function Banner() {
  return (
    <div className="banner-div">
      <img className="banner" src={banner} alt="banner"/>
    </div>
  );
}

export default Banner;
